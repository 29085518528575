import React from 'react';

const NewsPost = ({title, image, categoryName, categoryIconClass, excerpt, postAuthor, postDate, flipContent, link, video = '', link_new_tab = true }) => {
  const renderGraphics = () => {
    // Return video if any
    if (typeof(video) !== 'undefined' && video.length) {
        return <div className="embed-responsive embed-responsive-16by9">
            <iframe title={title} className="embed-responsive-item" src={video} allowFullScreen></iframe>
        </div>
    }

    // Return image
    return <div className="view overlay rounded shadow-3 mb-lg-0 mb-4">
            <img className="img-fluid" src={image} alt="Newspost"/>
            <div className="mask rgba-white-slight"></div>
        </div>
    }

  return (
    <div className={`row align-items-center ${flipContent ? "flex-row-reverse" : ""}`}>
        {/* Image */}
        <div className="col-lg-5">
            { renderGraphics() }
        </div>
        {/* News post content */}
        <div className="col-lg-7">
            {/* Category */}
            <h6 className="blue-text font-weight-bold mb-3">
                <i className={`${categoryIconClass} pr-2`}></i>
                {categoryName}
            </h6>
            {/* Post title */}
            <h4 className="bold mb-3">{title}</h4>
            {/* Post Excerpt */}
            <p>{excerpt}</p>
            {/* Post data / details */}
            <p className="text-muted">{ postAuthor ? <span>by <strong>{postAuthor}</strong>, </span> : <span>Posted on</span> } {postDate}</p>
            {/* Read more button */}
            {link ? <a href={link} className="btn btn-outline-info waves-effect z-depth-0 btn-md mx-0" target={link_new_tab ? '_blank' : ''} rel="noopener noreferrer">More info</a> : <></>}
        </div>
    </div>
  );
};

export default NewsPost;
