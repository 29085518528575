import React from 'react'
import styles from '../components/header-carousel-slide.module.css';

const HeaderCarousel = ({image, active, html, classes, skipLogo, darken, skipLogoSmall}) => {
    const activeClass = active ? "active" : "";
    const classNames = classes ? classes : "";
    const style = html ? {} : {
        backgroundImage: `url("${process.env.PUBLIC_URL}/img/overlays/${image}")`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    };
    const logo = skipLogo ? <></> :  <img src={`${process.env.PUBLIC_URL}/img/logo/logo_Plan3D_by_vansteelandt.png`} alt="Plan3D by vansteelandt" className={`${styles["header__img"]} ${skipLogoSmall ? styles["header__img-hide-small"] : ""}`}/>
    const content = <div className={`view ${html ? "" : "p-5"} ${classNames}`} style={style}>
        {html}
        <div className={`mask d-flex justify-content-center align-items-center ${darken ? styles["rgba-black-light--darker"] : ""}`}>
            <div className="text-center white-text wow fadeIn">
                {logo}
            </div>
        </div>
    </div>;

    return (
    // Paralax version
    // <div className={`carousel-item ${styles["header-carousel-item"]} ${activeClass}`}>
    //     <ParalaxSection backgroundImage={`${process.env.PUBLIC_URL}/img/overlays/${image}`} 
    //         overlayDark={true} strength={500} overlayClass="rgba-black-light" fill={true}>
    //         <div className="view p-5">
    //             <div className={`mask rgba-black-light d-flex justify-content-center align-items-center`}>
    //                 <div className="text-center white-text mx-5 wow fadeIn">
    //                     <img src={`${process.env.PUBLIC_URL}/img/logo/logo_Plan3D_by_vansteelandt.png`} alt="Plan3D by vansteelandt" height="120px"/>
    //                 </div>
    //             </div>
    //         </div>
    //     </ParalaxSection>
    // </div>

    <div className={`carousel-item ${styles["header-carousel-item"]} ${activeClass}`}>
        { content }
    </div>
    );
}

export default HeaderCarousel
