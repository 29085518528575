import React from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import ReactGA from 'react-ga';

import './clientZone.css';
import Section from '../components/sections/section';

const ClientZone = () => {
    const { user } = useAuth0();

    ReactGA.set({
        userId: user.email,
    });

    return (
        <>
            <Section title="Map update Webviewer" subtitle="Webviewer data">
                <div className="row">
                    <div className="col-md-12">
                        <div className="embed-container">
                            <iframe width="500" height="200" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" title="Kaart Webviewer" src="//vansteelandt.maps.arcgis.com/apps/Embed/index.html?webmap=8abadd8d4db045fb83e8864de8bc077d&amp;extent=0.8222,49.1346,8.1665,51.8388&amp;zoom=true&amp;previewImage=true&amp;scale=true&amp;search=true&amp;searchextent=true&amp;disable_scroll=true&amp;theme=light"></iframe>
                        </div>
                        <div className="text-center">
                            <a href="https://vansteelandt.maps.arcgis.com/apps/View/index.html?appid=55000c8da0d9479abe403ac327e99849" target="blank" alt="Kaart Webviewer app" className="btn btn-link waves-effect blue-text">
                                <i className="fas fa-external-link-alt blue-text mr-2"></i>
                                Show map app and last updates
                            </a>
                        </div>
                    </div>
                </div>
            </Section>
            <Section title="Map update Webservices" subtitle="Webservices data (wms,wmts)" accent={true}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="embed-container">
                            <iframe width="500" height="200" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" title="Kaart Webservices" src="//vansteelandt.maps.arcgis.com/apps/Embed/index.html?webmap=8dd43d9ee74848d991608951a6dd25ba&amp;extent=0.8222,49.1346,8.1665,51.8388&amp;zoom=true&amp;previewImage=true&amp;scale=true&amp;search=true&amp;searchextent=true&amp;disable_scroll=true&amp;theme=light">
                            </iframe>
                        </div>
                        <div className="text-center">
                            <a href="https://vansteelandt.maps.arcgis.com/apps/View/index.html?appid=be4fe1385d2b451d9bd4ff01b26a43e7" target="blank" alt="Kaart Webservices app" className="btn btn-link waves-effect blue-text">
                                <i className="fas fa-external-link-alt blue-text mr-2"></i>
                                Show map app and last updates
                            </a>
                        </div>
                    </div>
                </div>
            </Section>
        </>
    );
}

export default ClientZone
