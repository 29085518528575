import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ROUTES } from '../consts/index';
import Home from '../pages/home';
import Disclaimer from '../pages/legal/disclaimer';
import CookiePolicy from '../pages/legal/cookiePolicy';
import PrivacyPolicy from '../pages/legal/privacyPolicy';
import ServiceDetail from '../containers/serviceDetail';
import AsbestosNews from '../pages/asbestosNews';
import ClientZone from '../pages/clientZone';
import PrivateRoute from '../components/authentication/PrivateRoute'
import AsbestosDashboard from '../pages/asbestosDashboard';

const RoutingContent = () => {
  return (
    <section>
      <Switch>
        {/* Ga naar home page */}
        <Route exact strict path={ROUTES.home.page}>
          <Home />
        </Route>
        {/* Ga naar solution detail page */}
        <Route path={ROUTES.serviceDetail.path}>
          <ServiceDetail />
        </Route>
        {/* Ga naar news detail page */}
        <Route path={ROUTES.asbestosNews}>
          <AsbestosNews />
        </Route>
        {/* Ga naar klantenzone: Automatisch door Auth0 configuratie */}
        <PrivateRoute path={ROUTES.clientZone} component={ClientZone} />
        {/* Ga naar de legal privacy policy */}
        <Route exact strict path={ROUTES.privacyPolicy.to}>
          <PrivacyPolicy />
        </Route>
        {/* Ga naar de legal cookie policy */}
        <Route exact strict path={ROUTES.cookiePolicy.to}>
          <CookiePolicy />
        </Route>
        {/* Ga naar de legal disclaimer */}
        <Route exact strict path={ROUTES.disclaimer.to}>
          <Disclaimer />
        </Route>
        {/* Redirect naar externe url */}
        <Route exact path={ROUTES.asbestosDashboardAsbestos}>
          <AsbestosDashboard type="asbestos" />
        </Route>
        <Route exact path={ROUTES.asbestosDashboardArrondissement}>
          <AsbestosDashboard type="arrondissement" />
        </Route>
        {/* Redirect naar home bij niet bestaande url */}
        <Route exact path="*">
          <Redirect to={ROUTES.home.page} />
        </Route>
      </Switch>
    </section>
  );
};

export default RoutingContent;
