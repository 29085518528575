import React, {Component} from 'react';
import styles from './order-section.module.css';

class OrderSection extends Component {
    constructor(props) {
        super(props);
        this.baseLink = 'https://docs.google.com/forms/d/e/1FAIpQLSdNQuhiFXEti7iwH4X4CzeYbyFXbpnj3K40D0aOBCoxzuVqHQ/viewform?usp=pp_url&';
    }

    componentDidMount () {
        const jsOrderMap = document.createElement("script");
        jsOrderMap.src = "/js/order-map.js";
        jsOrderMap.async = true;
        document.body.appendChild(jsOrderMap);
    }

    render() { return (
        <>
            <div className="row px-5-md mb-1">
                <div className="col-md-8">
                    {/* Search address */}
                    <div id="dialog" className="position-relative">
                        <div className="input-group mb-0">
                            <input type="text" id="search" name="address" className={`form-control ${styles['order-map__search']}`} placeholder="Zoek adres" aria-label="Search term" aria-describedby="basic-addon2" />
                        </div>
                        <div id="suggesties" className="position-absolute z-10 w-100"></div>
                    </div>
                    {/* Map */}
                    <div className="position-relative">
                        {/* Layer options */}
                        <div className="position-absolute top-0 end-0 rounded z-1 shadow-1 bg-white m-1 p-2">
                            <strong className="fs-small">Lagen</strong>
                            <div className="mt-1 px-2 js-layers"></div>
                        </div>
                        <div id='order-map' className={`js-map ${styles['order-map']}`}></div>
                    </div>
                </div>
                {/* Stappen & CaPaKeys */}
                <div className="col-md-4 position-relative">
                    <h5>Stappen:</h5>
                    <ul className="stappen">
                        <li>Zoom in op gewenste perceel of geef een adres in</li>
                        <li>Selecteer maximum 3 percelen.</li>
                        <li>Klik op de 'Bestel' knop om de bestelbon in te vullen.</li>
                    </ul>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col" className="col-7 px-0 py-2">Capakey</th>
                                <th scope="col" className="col-4 px-0 py-2">NIS code</th>
                                <th scope="col" className="col-1 px-0 py-2"></th>
                            </tr>
                        </thead>
                        <tbody id="list"></tbody>
                    </table>

                    <div className="row align-items-center d-none js-table-loading">
                        <div className="spinner-border text-primary mx-auto" role="status">
                            <span className="visually-hidden">Laden...</span>
                        </div>
                    </div>

                    <a target="_blank" rel="noopener noreferrer" href={this.baseLink} className="btn btn-primary js-order position-absolute top-100 start-50 translate-middle mt-n-btn">Bestel</a>
                </div>
            </div>
            <div className="row px-5-md mb-4 justify-content-end">
                <div className="col-md-4">
                    <p className="text-danger mb-0 text-center fs-small">De bestelde producten worden pas aangemaakt en geleverd na ontvangst van betaling.</p>
                </div>
            </div>
    </>
)}};

export default OrderSection
