import React, {Component} from 'react';
import { ROUTES } from '../../consts/index';
import { HashLink as Link } from 'react-router-hash-link';
import Section from '../../components/sections/section';

class CookiePolicy extends Component {
    render() { return (
            <Section title="Disclaimer" fill={true}>
                <div className="section clearfix" id="top">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <p><i>Version 1, made on 01/08/2021</i></p>
                                <p>&nbsp;</p>
                                <p>This website is owned by Vansteelandt BV</p>
                                <ul>
                                    <li>Contact details: Vansteelandt BV, BE 0451.487.488</li>
                                    <li>Registered office: Vaartlaan 28/1, 9800 Deinze</li>
                                    <li>Phone number: +32 (0)9 243 81 10</li>
                                    <li>E-mail address: office@vansteelandt.be</li>
                                </ul>
                                <p>
                                    The use of the websites www.vansteelandt.be and www.plan3d.be (the “Website”) is subject to the
                                    terms of use set out below. Use of the website constitutes acceptance of these terms and conditions.
                                    The latter are subject to change: the applicable conditions are those in force at the time of each
                                    connection. The Website belongs to Vansteelandt BV, BE 0451.487.488, Vaartlaan 28/1, 9800 Deinze
                                    (hereinafter: “We” or the “Company”).
                                </p>
                                <h4>Use of the website</h4>
                                <p>
                                    We pay a lot of attention to our Website. Despite these efforts, we cannot guarantee that the
                                    information, data or publications on the Website are completely correct, accurate, exhaustive
                                    and current. This data can therefore only be used as general information. Nor can we be held
                                    liable for direct or indirect damage arising from the use of the Website or on the basis of
                                    information, data or publications made available via the Website. The Website may contain files
                                    of third parties or hyperlinks or other references to websites of third parties. Our Company is
                                    in no way liable for this. Furthermore, we reserve the right to adapt, change or supplement the
                                    content of the Website at any time. We accept no responsibility for any temporary unavailability
                                    of this website or for any loss or damage resulting from visiting the website. We do not accept
                                    any responsibility for delay caused by third parties or incorrect information provided by them.
                                </p>
                                <h4>
                                    Intellectual Property
                                </h4>
                                <p>
                                    The use of the Website does not grant the user any intellectual property rights. The content of
                                    the Website and the information made available belong to the exclusive intellectual property of
                                    the Company or of the party whose source is mentioned. The information that can be consulted on
                                    the Website, the design, texts, drawings, photos, data, logos, brands and other elements that
                                    are displayed are protected by trademark law and/or copyright and/or other applicable
                                    regulations and may not be reproduced, transferred, sold, distributed, etc. in any form and by
                                    any means. Any infringement will give rise to legal action.
                                </p>
                                <h4>
                                    Protection of personal data
                                </h4>
                                <p> The secure, transparent and confidential collection and processing of personal data is highly
                                    valued by our company. Our Company takes its privacy and data protection obligations seriously.
                                    For more information, you can consult our Privacy Policy on the Website, via the following link:
                                    <Link to={ROUTES.privacyPolicy.path}> privacy </Link><br />
                                    The Website may use cookies, which make it possible to store information to simplify the use of
                                    the Website. You can manage and/or disable the use of cookies in your browser. In the latter
                                    case, however, your visit may not go as smoothly. In addition, the acceptance of cookies, via
                                    your browser, may be necessary to reach parts of the Website that require the identification of
                                    the user. For more information, you can consult our Cookie Policy on the Website, via the
                                    following link:
                                    <Link to={ROUTES.cookiePolicy.path}> cookies </Link>
                                </p>
                                <h4>
                                    E-mail
                                </h4>
                                <p>
                                    Our emails and their attachments are confidential and intended for use by the addressee. The
                                    message and its attachments may contain information for limited use or be subject to copyright
                                    or other legal provisions. If you receive such communication in error or if you are not the
                                    intended addressee, any publication, reproduction, copy, distribution or other distribution
                                    thereof is strictly prohibited. In that case, we request that you let Vansteelandt BV know by
                                    sending a reply message and then delete the message. All messages to and from Vansteelandt BV
                                    and its departments can be checked for compliance with internal regulations to protect our
                                    activities and/or to remove possible 'malware'. Emails are not secure and errors are always
                                    possible through interception, alteration, loss or destruction. We believe, but do not warrant,
                                    that the email and its attachments are secure and free of viruses. Anyone who communicates with
                                    us via email is deemed to accept these risks.
                                </p>
                                <h4>
                                    Belgian law
                                </h4>
                                <p>
                                    Your access to, visit or use of the Website and these terms of use are governed by Belgian law.
                                    In the event of a dispute, only the courts of the Company's registered office are competent.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
    )};
}

export default CookiePolicy
