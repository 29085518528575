import React, {Component} from 'react'
import Section from '../components/sections/section';
import OrderSection from '../components/sections/order-section';

class ServiceDetailOrder extends Component {
    render() { return (
        <>            
            <Section title='' fill={false}
                image={`${process.env.PUBLIC_URL}/img/sections/plan3dbg.jpg`}
                overlayClass="rgba-blue-light">
                <div className="container">
                    <div className="row d-flex justify-content-center my-5">
                        <div className="col-12 col-md-6 col-lg-5">
                            <img className="w-100" src={`${process.env.PUBLIC_URL}/img/logo/logo_Plan3D_terreinopmetingen.png`} alt="Plan3D terreinopmetingen" />
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-md-12 text-white">
                            <p className="text-center">Bestel de opmeting voor uw digitale aanvraag van een omgevingsvergunning.</p>
                        </div>
                    </div>
                </div>
            </Section>

            <Section title='Waarom Plan3D' fill={false} accent={true}>
                <span id="why" className="scroll-fix-item"></span>
                <div className="row">
                    <div className="col-md-12 text-center">                    
                        <p>Terreinopmeting inclusief terreinprofielen, binnen 5 à 10 werkdagen in uw mailbox.</p>
                        <p>Voordelen zijn: direct beschikbaar, aantrekkelijke prijs, geen aanwezigheid op terrein vereist, geen tijdverlies.</p>
                    </div>
                </div>
            </Section>

            <Section title='Wat bevat Plan3D' fill={false} accent={false}>
                <span id="what" className="scroll-fix-item"></span>
                <div id="section2" className="clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <p>Basisdocumenten voor uw digitale aanvraag van een omgevingsvergunning.</p>
                                <p>Uw bestelling wordt opgesteld volgens het document::<br/>
                                    <a href="https://www.omgevingsloketvlaanderen.be/normenboeken-voor-dossiersamenstelling" target="_blank" rel="noopener noreferrer">
                                        <i>Normen voor digitale aanvragen voor een omgevingsvergunning. Stedenbouwkundige handelingen met architect.</i>
                                    </a>
                                </p>
                                <h3>Wij leveren</h3>
                                <ul>
                                    <li>
                                        <strong>Liggings- en omgevingsplan (shape bestand)</strong>
                                        <ul className="fs-small">
                                            <li>Geocontour op basis van GRB voor het opladen in omgevingsloket</li>
                                            <li>Geocontour geldt als liggings- en omgevingsplan</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Inplantingsplan (dxf bestand)</strong>
                                        <ul className="fs-small">
                                            <li>de noordpijl</li>
                                            <li>de weg waaraan het goed paalt of vanwaar het goed bereikt kan worden met de
                                                aanduiding waar
                                                er verharding is en de berijdbare breedte ervan</li>
                                            <li>de ter hoogte van het goed op het openbaar domein voorkomende beplantingen,
                                                bovengrondse
                                                infrastructuur en straatmeubilair</li>
                                            <li>de aanduiding van de plaatsen waar de terreinprofielen werden genomen (indien
                                                terreinprofielen
                                                noodzakelijk zijn)</li>
                                            <li>de aanzet van de perceelgrenzen van de percelen, palend aan het goed tot op
                                                minstens drie
                                                meter buiten de uiterste grenzen van het goed, met minstens het bovenaanzicht van
                                                de
                                                hierbinnen voorkomende bebouwing, met vermelding van haar functie, kadastraal
                                                nummer
                                                en huisnummer</li>
                                            <li>de vermelding van het gebruik van de gronden</li>
                                            <li>de nutsvoorzieningen worden niet meegeleverd</li>
                                            <li>bovenaanzicht van de geplande werken en vermelding van de bestaande
                                                erfdienstbaarheden dienen
                                                aangebracht te worden door de architect</li>
                                            <li>de afmetingen van de bestaande toestand met de fysische kenmerken van de grens, niet
                                                de juridische grens</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>2 terreinprofielen (dxf bestand)</strong>
                                        <ul className="fs-small">
                                            <li>het reliëf van het goed voor de geplande werken</li>
                                            <li>een referentiepeil op de weg waaraan het goed paalt</li>
                                            <li>het peil van de percelen, palend aan het goed</li>
                                            <li>als ze voorkomt, het profiel van de bestaande te behouden of te slopen bebouwing
                                            </li>

                                            <li>de invulling van het openbaar domein (voetpad, rijweg, fietspad, parkeerstrook,
                                                groenzone...)</li>
                                        </ul>
                                        <ul className="fs-small">
                                            <li>
                                                het reliëf na de uitvoering van de geplande werken en het profiel van de geplande
                                                werken dienen aangebracht te worden door
                                                de architect
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Foto’s in vogelperspectief (jpg bestanden)</strong>
                                    </li>
                                    <li>
                                        <strong>Orthofoto + kartering (pdf bestand)</strong>
                                    </li>
                                    <li>
                                        <strong>3D model (dxf bestand)</strong>
                                    </li>
                                </ul>
                                <p>De juridische grensbepaling is optioneel te bestellen in regie. Deze optie kan u aanvinken op het bestelformulier.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Voorbeelden</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="thumbnail">
                                    <img className="w-100 h-100" src={`${process.env.PUBLIC_URL}/img/order/3dmodel.jpg`} alt="3d model"/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="thumbnail">
                                    <img className="w-100 h-100" src={`${process.env.PUBLIC_URL}/img/order/ortho-met.jpg`} alt="oblieke foto"/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="thumbnail">
                                    <img className="w-100 h-100" src={`${process.env.PUBLIC_URL}/img/order/terreinprof.jpg`} alt="terreinprofiel"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>

            <Section title='Kostprijs' fill={false} accent={true}>
                <span id="price" className="scroll-fix-item"></span>
                <p className="text-center">
                Voor € 495 exclusief btw (€ 598,95 inclusief btw) ontvangt u alles, binnen 5 à 10 werkdagen.
                </p>
            </Section>

            <Section title='Bestel in drie stappen' fill={false} accent={false}>
                <span id="order" className="scroll-fix-item"></span>
                <OrderSection />
            </Section>
        </>
    )};
}

export default ServiceDetailOrder
