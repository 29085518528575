import React, { useState, useEffect } from 'react';
import styles from './client-carousel.module.css';


const ClientCarousel = ({ title, bgTransparent, dataType}) => {
    const [clients, setData]=useState([]);
    const _getData=(type) => {
        let toFetch = '';
        switch(type) {
            case 'clients':
                toFetch = 'data/clients.json';
                break;
            case 'partners':
                toFetch = 'data/partners.json';
                break;
            case 'memberships':
                toFetch = 'data/memberships.json';
                break;
            default:
                toFetch = '';
                break;
        }

        fetch(toFetch, {
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((jsonData) => {
            setData(jsonData);
        });
    }

    // Get client/partner/membership data
    useEffect(() => _getData(dataType),[dataType]);

    // Get image path
    const defaultPathImages = `${process.env.PUBLIC_URL}/img/${dataType}`;
    return (
        <div className={`${bgTransparent ? "" : "bg-white"}`}> 
            {title ? <p className="pb-2 m-0 lead font-weight-bold"> {title} </p> : <></ > } 
            <div className={`${dataType === 'clients' ? 'customer-logos' : "row align-items-center justify-content-center"}`} >
                     {clients.map((client, idx) => {
                        return (
                            <div key={`div-${idx}`} className={`${dataType === 'clients' ? 'slide' : 'col-8 col-sm-4 mb-4 mb-sm-0'}`}>
                                <a key={`a-${idx}`}
                                    href={client.website}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="slide d-flex justify-content-center" >
                                    <img key={`img-${idx}`} 
                                        src={`${defaultPathImages}/${client.name}.${client.imageExtension}`}
                                        className={`img-fluid px-4 ${styles["client-carousel-logo"]}`}
                                        alt={client.name}/> 
                                </a>
                            </div>)
                        })
                    } 
            </div>
        </div>
)};

export default ClientCarousel
 