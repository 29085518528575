import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ROUTES } from '../consts/index';
import ServiceDetailDeepLearning from '../pages/serviceDetailDeepLearning';
import ServiceDetailProjects from '../pages/serviceDetailProjects';
import ServiceDetailContent from '../pages/serviceDetailContent';
import ServiceDetailOrder from '../pages/serviceDetailOrder';
import ServiceDetailOtherProducts from '../pages/serviceDetailOtherProducts';

const ServiceDetail = () => {
  return (
    <section>
      <Switch>
        {/* Ga naar detail page van content */}
        <Route exact strict path={ROUTES.serviceDetailContent}>
          <ServiceDetailContent />
        </Route>
        {/* Ga naar detail page van projects */}
        <Route exact strict path={ROUTES.serviceDetailProjects}>
          <ServiceDetailProjects />
        </Route>
        {/* Ga naar detail page van order */}
        <Route exact strict path={ROUTES.serviceDetailOrder}>
          <ServiceDetailOrder />
        </Route>
        {/* Ga naar detail page van deep learning */}
        <Route exact strict path={ROUTES.serviceDetailDL}>
          <ServiceDetailDeepLearning />
        </Route>
        {/* Ga naar detail page van deep learning */}
        <Route exact strict path={ROUTES.serviceDetailOtherProducts}>
          <ServiceDetailOtherProducts />
        </Route>
        {/* Ga naar enquête deep learning priate domeinen */}
        <Route exact strict path={ROUTES.serviceDetailDLEnquetePrivate}>
          <div className="d-flex justify-content-center">
            <iframe title="Form DL Private" className="mx-auto" src="https://docs.google.com/forms/d/e/1FAIpQLSf5jpA2CTEZJKzNxFeG86-n1EOwDoZ312_GoFT0Xf1bYt4vLw/viewform?embedded=true" width="640" height="5500" frameborder="0" marginheight="0" marginwidth="0">Laden…</iframe>
          </div>
        </Route>
        {/* Ga naar enquête deep learning publieke domeinen */}
        <Route exact strict path={ROUTES.serviceDetailDLEnquetePublic}>
          <div className="d-flex justify-content-center">
            <iframe title="Form DL Public" className="mx-auto" src="https://docs.google.com/forms/d/e/1FAIpQLSdFofuvWW_B48qeOJax-WPjl2RZWIOWYaCY5iuVbtbRCxgRLQ/viewform?embedded=true" width="640" height="5500" frameborder="0" marginheight="0" marginwidth="0">Laden…</iframe>
          </div>
        </Route>
        {/* Redirect naar services bij niet bestaande url */}
        <Route exact path={`${ROUTES.serviceDetail.to}*`}>
          <Redirect to={ROUTES.services.to} />
        </Route>
      </Switch>
    </section>
  );
};

export default ServiceDetail;
