import React, { useState, useEffect } from 'react';
import { ref, onValue } from "firebase/database";
import styles from './data-coverage.module.css';
import Counter from './counter';
import { database } from '../consts/firebase-config';

const DataCoverage = () => {
    const [counterInfo, setCounterInfo] = useState({ status: "...", data: null, error: null });

    // Get coverage data
    useEffect(() => {
        // build reference
        const licenseCountRef = ref(database); // Info is on root of database
    
        // attach listener
        onValue(licenseCountRef, (snapshot) => {
            setCounterInfo({
                status: "ready",
                data: snapshot.val(),
                error: null
            });
        });
    }, []); // <- run above code whenever [] changes

    return (
        <div className={`${styles["data-coverage-container"]}`}>
            <div className={`${styles["counter-container"]} row no-gutters`}>
                <Counter name="Joined Licenses Plan3D" from={0} to={counterInfo?.data?.licenses ?? 0} timeMs={5000} lineColor="rgb(0,168,132)" />
                <Counter name={<>Available surface area km<sup>2</sup></>} from={0} to={counterInfo?.data?.area ?? 0} timeMs={5000} lineColor="rgb(0,168,132)"/>     {/* unit={`${Math.floor(data.area/data.areaVlaanderen*100)}%`} */}
            </div>
            <div className={`${styles["data-coverage-map"]}`}>
                <iframe className="responsive-iframe" width="500" height="250" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" title="Kaart Webviewer" src="https://vsdeinze.github.io/WV1/#9/50.9437/4.3259"></iframe>
            </div>
        </div>
)};

export default DataCoverage
 