import React from 'react';
// import { ROUTES } from '../../consts/index';
// import { HashLink as Link } from 'react-router-hash-link';

const OtherProductsSection = () => {
    return (
        <section className="dark-grey-text section-fill">
            <div className="container pt-5 mb-5">
                <div className="row justify-content-center mb-2 mb-md-4">
                    <div className="col-xl-10 pl-3 d-flex align-items-center">
                        <div>
                            <h3 className="font-weight-bold mb-4">Other products</h3>
                            <h5>Unlock the Power of AI-Driven Insights</h5>
                            <p>
                                Explore our suite of AI-derived products designed to revolutionize how you see and understand the world around you. At Vansteelandt bv, we're proud to introduce four game-changing solutions that meet a wide range of industries and needs.
                            </p>
                            <ol>
                                <li>
                                Permeability Maps: Gain access to GIS maps that reveal the percentage of non-water permeable materials on parcels, aiding in urban planning and environmental assessments.
                                </li>
                                <li>
                                Asbestos Suspected Buildings: Identify asbestos risk in every building in Flanders. Our AI classifies them as 'suspected asbestos,' 'not suspected asbestos,' or 'not clearly visible.'
                                </li>
                                <li>
                                Road Markings: Get precise estimates of paint requirements for road markings in a specific area. Our GIS files come complete with specific attributes to assist your paint estimation needs.
                                </li>
                                <li>
                                Tree Inventorization: Get a detailed view of individual trees in a specific area, complete with measurements and vital information.
                                </li>
                            </ol>
                            <p>
                                All of our AI-driven products are available on demand, and we're here to customize these solutions to meet your unique needs. Contact us for inquiries, demos, and pricing, and let's transform your vision into reality with data-driven insights tailored to your specific requirements.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row justify-space-between mt-4">
                    <div className="col-md-6 mb-4 mb-md-0">
                        <div className="view">
                            <h4 className='text-center'>Permeability maps</h4>
                            <img src={`${process.env.PUBLIC_URL}/img/service-details/verhardingskaart.jpg`} className="img-fluid" alt="Permeability maps" />
                        </div>
                    </div>
                    <div className="col-md-6 mb-4 mb-md-0">
                        <div className="view">
                            <h4 className='text-center'>Asbestos suspected buildings</h4>
                            <img src={`${process.env.PUBLIC_URL}/img/service-details/asbest_verdachte_gebouwen.jpg`} className="img-fluid" alt="Asbestos suspected buildings" />
                        </div>
                    </div>
                </div>
                <div className="row justify-space-between mt-4">
                    <div className="col-md-6 mb-4 mb-md-0">
                        <div className="view">
                            <h4 className='text-center'>Road marking inventorization</h4>
                            <img src={`${process.env.PUBLIC_URL}/img/service-details/wegmarkeringen.jpg`} className="img-fluid" alt="Road markings" />
                        </div>
                    </div>
                    <div className="col-md-6 mb-4 mb-md-0">
                        <div className="view">
                            <h4 className='text-center'>Tree inventorization</h4>
                            <img src={`${process.env.PUBLIC_URL}/img/service-details/bomen.jpg`} className="img-fluid" alt="Trees" />
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
};

export default OtherProductsSection
