import React from "react";
import Spinner from 'react-spinner-material';

const Loading = () => {
  return (
    <div className="vw-100 vh-100 d-flex align-items-center justify-content-center">
        <Spinner visible={true} color={"rgba(28, 35, 49, 0.8)"} radius={60} />
    </div>
  )
};

export default Loading;
