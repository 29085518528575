import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = ({ text }) => {
  const { logout } = useAuth0();

  return (
    <button className="btn btn-sm btn-outline-warning"
            onClick={() => logout({ returnTo: window.location.origin })}>
      {text}
    </button>
  );
};

export default LogoutButton;
