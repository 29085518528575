import React from 'react';
import './App.css';
import NavBar from './components/navbar';
import Footer from './components/footer';
import Loading from './components/loading';
import RoutingContent from './containers/routing';
import { useAuth0 } from '@auth0/auth0-react';

function App() {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
        <NavBar />
        <RoutingContent />
        <Footer />
    </>
  );
}

export default App;
