import React, {Component} from 'react'
import OtherProductsSection from '../components/sections/other-products-section'

class ServiceDetailOtherProducts extends Component {
    render() { return (
        <>
            <OtherProductsSection />
        </>
    )};
}

export default ServiceDetailOtherProducts
