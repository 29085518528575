import React, {Component} from 'react';
import Section from '../components/sections/section';
import DataServices from '../components/sections/data-services';
import ImageSection from '../components/sections/image-section';

class ServiceDetailContent extends Component {
    render() { return (
        <>
            <Section title="Image content" subtitle={<span className="h6">Content program of Benelux<br/><b>Flanders</b> fully covered (mostly) with GSD 3-4 cm<br/><b>Brussels-Capital Region</b> fully covered with GSD 7-8 cm<br/></span>}
                fullWidth={true} accent={false} fill={true}>
                <DataServices />
            </Section>

            <ImageSection contentTitle="Web viewer" contentText="Oblique focused aerial images from 4 directions, true-orthomosaics and 3D point clouds can be accessed in a very user-friendly Web Viewer environment. Distances and surfaces can be measured within each window, and profile views can be taken in the point cloud. Strips of 3D points can be downloaded in LAS format."
                        images={[
                            {Source: `${process.env.PUBLIC_URL}/img/content/webviewer/industr01_sm.jpg`, Alt: 'Webviewer detail 1'},
                            {Source: `${process.env.PUBLIC_URL}/img/content/webviewer/industr02_sm.jpg`, Alt: 'Webviewer detail 2'},
                            {Source: `${process.env.PUBLIC_URL}/img/content/webviewer/industr03_sm.jpg`, Alt: 'Webviewer detail 3'},
                            {Source: `${process.env.PUBLIC_URL}/img/content/webviewer/industr04_sm.jpg`, Alt: 'Webviewer detail 4'},
                            {Source: `${process.env.PUBLIC_URL}/img/content/webviewer/industr05_sm.jpg`, Alt: 'Webviewer detail 5'},
                            {Source: `${process.env.PUBLIC_URL}/img/content/pc/point03_sm.jpg`, Alt: 'Webviewer point cloud'},
                            {Source: `${process.env.PUBLIC_URL}/img/content/pc/pointmeet02_sm.jpg`, Alt: 'Webviewer point cloud'},
                            {Source: `${process.env.PUBLIC_URL}/img/content/webviewer/kasteel01_sm.jpg`, Alt: 'Webviewer detail 6'},
                            {Source: `${process.env.PUBLIC_URL}/img/content/webviewer/kasteel03_sm.jpg`, Alt: 'Webviewer detail 8'},
                            {Source: `${process.env.PUBLIC_URL}/img/content/webviewer/kasteel04_sm.jpg`, Alt: 'Webviewer detail 9'},
                            {Source: `${process.env.PUBLIC_URL}/img/content/webviewer/kasteel05_sm.jpg`, Alt: 'Webviewer detail 10'},
                            {Source: `${process.env.PUBLIC_URL}/img/content/webviewer/station01_sm.jpg`, Alt: 'Webviewer detail 11'},
                            {Source: `${process.env.PUBLIC_URL}/img/content/webviewer/station03_sm.jpg`, Alt: 'Webviewer detail 13'},
                            {Source: `${process.env.PUBLIC_URL}/img/content/webviewer/station04_sm.jpg`, Alt: 'Webviewer detail 14'},
                            {Source: `${process.env.PUBLIC_URL}/img/content/webviewer/station05_sm.jpg`, Alt: 'Webviewer detail 15'}
                        ]}
                        sectionId='webviewer'
                        flip={false} accent={false} vertical={true} heightClass="image-section" className="mb-5 min-h-100">
                <span id="webviewer" className="scroll-fix-item"></span>
            </ImageSection>
            <div className="container">
                <div className="row justify-content-center p-3">
                    <div className="embed-responsive embed-responsive-16by9 shadow-1">
                        <iframe title={"Plan3D Image Content"} className="embed-responsive-item" src='https://www.youtube.com/embed/7Mr9449Q-dk' allowFullScreen></iframe>
                    </div>
                </div>
            </div>

            <ImageSection contentTitle="Web service" contentText="Ortho WMS (Web Map Service) and Ortho WMTS (Web Map Tile Service), detailed true-orthomosaics can be accessed as a reference layer for use within own (e.g. GIS) applications."
                        images={[
                            {Source: `${process.env.PUBLIC_URL}/img/content/webservice/webservice_zoom1.jpg`, Alt:'Webservice zoom 1'},
                            {Source: `${process.env.PUBLIC_URL}/img/content/webservice/webservice_zoom2.jpg`, Alt:'Webservice zoom 2'},
                            {Source: `${process.env.PUBLIC_URL}/img/content/webservice/webservice_zoom3.jpg`, Alt:'Webservice zoom 3'},
                            {Source: `${process.env.PUBLIC_URL}/img/content/webservice/webservice2_sm.jpg`, Alt:'Webservice detail 1'},
                            {Source: `${process.env.PUBLIC_URL}/img/content/webservice/webservice3_sm.jpg`, Alt:'Webservice detail 2'},
                            {Source: `${process.env.PUBLIC_URL}/img/content/webservice/webservice4_sm.jpg`, Alt:'Webservice detail 3'},
                            {Source: `${process.env.PUBLIC_URL}/img/content/webservice/webservice5_sm.jpg`, Alt:'Webservice detail 4'},
                            {Source: `${process.env.PUBLIC_URL}/img/content/webservice/webservice6_sm.jpg`, Alt:'Webservice detail 5'},
                        ]}
                        sectionId='webservice'
                        flip={false} accent={false} vertical={true} heightClass="image-section" className="mb-5 min-h-100">
                <span id="webservice" className="scroll-fix-item"></span>
            </ImageSection>
        </>
    )};
}

export default ServiceDetailContent
