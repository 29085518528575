import React, {Component} from 'react'

class Section extends Component {
    render() { return (
        <section className={`view ${this.props.fill ? "section-fill" : ""} ${this.props.accent ? "section-accent" : this.props.accentDark ? "section-accent-dark" : ""} scroll-fix ${this.props.wavesClass} ${this.props.image ? "section-bg" : ""} ${this.props.className}`} data-image={this.props.image}>
            {this.props.image ?
                <div className={`section-bg-image ${this.props.blur ? 'blur': ''}`} style={{ 
                        backgroundImage: `url("${this.props.image}")`,
                    }}></div> : <></>}
            { this.props.overlayClass ? <div className={`mask ${this.props.overlayClass} z-0`}></div> : <></>}

            <div className={`${this.props.fullWidth ? "container-fluid " : "container " }d-flex flex-column p-4 z-1`}>
                <h3 className={`h3 font-weight-bold text-center mt-2 section-title z-1 ${this.props.titleClass ? this.props.titleClass :  ""}`}>{this.props.title}</h3>
                {this.props.title ? <hr className={`w-header my-4 z-1 ${this.props.titleClass ? this.props.titleClass :  ""}`} /> : <> </>}

                {this.props.subtitle ?<p className="text-center mx-auto w-responsive mb-5 z-1"> {this.props.subtitle} </p> : <> </>}

                {this.props.children}
            </div>
        </section>
    )};
}

export default Section

/*
            style={ this.props.image ? {
                backgroundImage: `url("${this.props.image}")`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            } : {}}
*/