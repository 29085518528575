import React, {Component} from 'react'
import DeepLearningSection from '../components/sections/deep-learning-section';

class ServiceDetailDeepLearning extends Component {
    render() { return (
        <>
            <DeepLearningSection />
        </>
    )};
}

export default ServiceDetailDeepLearning
