import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { ROUTES } from '../../consts/index';

const DataServices = () => {
    return (
        <div className="row justify-content-evenly">
            {/* Webviewer */}
            <div className="col-xl-6 mb-4">  {/* animated fadeInLeft */}
                <div className="row no-gutters bg-white shadow-1 h-100">
                    <div className="col-md-9">
                        <div className="view overlay rounded">
                            <img src={`${process.env.PUBLIC_URL}/img/content/webviewer.jpg`} alt="webviewer example" className="img-fluid w-100"></img>
                            <div className="mask rgba-white-slight"></div>
                        </div>
                    </div>
                    <div className="col-md-3 p-2">
                        <div className="h-70">
                            <h4 className="mb-md-2">Web viewer</h4>
                            {/* <p className="">
                                We make relatively up-to-date Plan3D data with high detail visible via web viewer.
                                In this web app where you can view ortho &amp; oblique images with a license model
                            </p> */}
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">Orthomosiacs</li>
                                <li className="list-group-item">Oblique</li>
                                <li className="list-group-item">3D point clouds</li>
                            </ul>
                        </div>
                        <div className="h-30 text-center">
                            <Link className="btn btn-outline-info waves-effect shadow-0 p-lg-2 p-xl-auto" to={ROUTES.contentDetailWebviewer}>More info</Link>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Webservice */}
            <div className="col-xl-6 mb-4">  {/* animated fadeInRight */}
                <div className="row no-gutters bg-white shadow-1 flex-row-reverse h-100">
                    <div className="col-md-9">
                        <div className="view overlay rounded">
                            <img src={`${process.env.PUBLIC_URL}/img/content/webservice.jpg`} alt="webservice example" className="img-fluid w-100"></img>
                            <div className="mask rgba-white-slight"></div>
                        </div>
                    </div>
                    <div className="col-md-3 p-2">
                        <div className="h-70">
                            <h4 className="mb-md-2">Web service</h4>
                            {/* <p>Web Map Services and Web Map Tile Services</p> */}
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">Orthomosiacs</li>
                            </ul>
                        </div>
                        <div className="h-30 text-center">
                            <Link className="btn btn-outline-info waves-effect shadow-0 p-lg-2 p-xl-auto" to={ROUTES.contentDetailWebservice}>More info</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
)};

export default DataServices
