import React, { Component } from 'react';
import ImageCarousel from '../image-carousel';

class ImageSection extends Component {
    render() { return (
        <div className={`${this.props.accent ? "section-accent" : ""} w-100 scroll-fix ${this.props.wavesClass} ${this.props.className}`}>
            <div className="container">
                {this.props.children}
                <div className={`row no-gutters align-items-center ${this.props.flip ? "flex-row-reverse" : ""}`}>
                    <div className={`${this.props.vertical ? "" : "col-lg-4"} mx-auto d-flex align-items-center`}>  {/* animated ${this.props.flip ? "fadeInLeft" : "fadeInRight"} */}
                        <div className="p-4">
                            <h3 className="font-weight-normal mb-4">{this.props.contentTitle}</h3>
                            <p className="mb-0">{this.props.contentText}</p>
                        </div>
                    </div>

                    <div className={`${this.props.vertical ? "" : "col-lg-8"} view`}>  {/* animated ${this.props.flip ? "fadeInRight" : "fadeInLeft"} */}
                        <ImageCarousel images={this.props.images} id={this.props.sectionId} />
                    </div>
                </div>
            </div>
        </div>
    )}
}

export default ImageSection
