import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ReactGA from 'react-ga';

const LoginButton = ({text}) => {
  const { loginWithRedirect } = useAuth0();
    
  return (
    <button className="btn btn-sm btn-outline-white"
            onClick={() => {
              ReactGA.event({
                category: "Sign in",
                action: "User pressed clientzone sign in button",
              });
              loginWithRedirect()}}>
        {text}
    </button>);
};

export default LoginButton;
