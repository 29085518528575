import React, {Component} from 'react'

class ContactInfo extends Component {
  render() { return (
    <ul className="row list-unstyled mb-0">
        <li className="col-sm-6 col-lg-3">
            <i className="fas fa-envelope white-text fa-md mb-3"></i>
            <p className="mb-3 mb-lg-0">sales@vansteelandt.be</p>
        </li>
        <li className="col-sm-6 col-lg-3">
            <i className="fas fa-phone white-text fa-md mb-3"></i>
            <p className="mb-3 mb-lg-0">+32 9 243 81 10</p>
        </li>
        <li className="col-sm-6 col-lg-3">
            <i className="fas fa-building white-text fa-md mb-3"></i>
            <p className="mb-3 mb-lg-0">VAT BE 0451.487.488</p>
        </li>
        <li className="col-sm-6 col-lg-3">
            <i className="fas fa-map-marker-alt white-text fa-md mb-3"></i>
            <p className="mb-3 mb-lg-0">Vaartlaan 28/1, 9800 Deinze</p>
        </li>
    </ul>
    )};
}

export default ContactInfo