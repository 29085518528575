import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
import Auth0ProviderWithHistory from './services/auth0-with-history';
/* GA */
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';

const history = createBrowserHistory();

const trackingId = "UA-100374222-1";          // Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname)    // Push Initial view
// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname });   // Update the user's current page
  ReactGA.pageview(location.pathname);        // Record a pageview for the given page
});

ReactDOM.render(
  <BrowserRouter history={history}>
    <Auth0ProviderWithHistory>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Auth0ProviderWithHistory>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
