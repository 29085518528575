import React, {useEffect} from 'react'

const AsbestosDashboard = ({type}) => {
    const _getData=() => {
        const toFetch = '/data/asbestos-link.json';
        fetch(toFetch, {
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((clientJson) => {
            const url = type === 'asbestos' ? clientJson.urlAsbestos : clientJson.urlArrondissement;
            window.location.assign(url);
        });
    }

    // Get client/partner data
    useEffect(() => _getData());

    const css = '#navbarMenu, .page-footer {display: none; }';
    return (
        <>
            <style> {css} </style>
        </>
    );
}

export default AsbestosDashboard
