import React, {Component} from 'react'
import Section from '../components/sections/section';
import Steps from '../components/steps';

class ServiceDetailProjects extends Component {
    render() { return (
        <>
            <Section title="Projects" subtitle="Projects on demand. Any sensor, at any time." fill={false}>
                <div className="row d-flex justify-content-center wow fadeIn my-auto mb-2">
                    <div className="col-md-4 col-xl-3 mb-4 mb-md-0">
                        <div className="card shadow-1 px-4 h-100"> {/* border-bottom-0 border-left-0 border-right-0 border-top border-4 border-primary */}
                            <h5 className="font-weight-normal pt-4 mb-4 dark-grey-text">
                                On demand
                            </h5>
                            <p className="text-muted mb-5">
                                For our customers we fly their projects on demand.
                            </p>
                        </div>
                    </div>

                    <div className="col-md-4 col-xl-3">
                        <div className="card shadow-1 px-4 h-100"> {/* border-bottom-0 border-left-0 border-right-0 border-top border-4 border-success */}
                            <h5 className="font-weight-normal pt-4 mb-4 dark-grey-text">
                                On existing data
                            </h5>
                            <p className="text-muted mb-5">
                                Ask for extra insights on existing data.
                            </p>
                        </div>
                    </div>

                    <div className="col-md-4 col-xl-3">
                        <div className="card shadow-1 px-4 h-100"> {/* border-bottom-0 border-left-0 border-right-0 border-top border-4 border-success */}
                            <h5 className="font-weight-normal pt-4 mb-4 dark-grey-text">
                                Special operations
                            </h5>
                            <p className="text-muted mb-5">
                                We serve you with the best solution.
                            </p>
                        </div>
                    </div>

                    <div className="w-100"></div>
                </div>

                <div className="row no-gutters align-items-center justify-content-center mb-2">
                    <div className="col-md-8 col-lg-7 order-md-2">
                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe title="Project BVLOS Elia group" className="embed-responsive-item" src="https://www.youtube.com/embed/S53AgBdaJBQ?rel=0" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            </Section>

            <Section title="Multicam" subtitle="Homemade aerial image sensor that combines multiple nadir and 4 oblique oriented cams. Complete villages can be flown in a few hours with a GSD of 3 cm." fill={false} accent={true}>
                <div className="row align-items-center justify-content-center">
                    {/* <div className="col-lg-6 mb-4">
                        <div className="view shadow-1-half rounded">
                            <img className="rounded img-fluid" src="https://mdbootstrap.com/img/Photos/Horizontal/Work/12-col/img%20(6).jpg" alt="Video title" />
                            <div className="mask flex-center rgba-black-light"></div>
                        </div>
                    </div> */}

                    <div className="col-lg-6 mb-4 text-center">
                        Products:
                        <Steps steps={[
                            {name: "", info: "True-Orthomosaics"},
                            {name: "", info: "Oblique images from 4 directions"},
                            {name: "", info: "3D point clouds from image processing"},
                        ]} />
                    </div>
                </div>
            </Section>

            <Section title="City Mapper" subtitle="The Citymapper is a technology and top-level product from Hexagon Leica Geosystems. The Citymapper is the only hybrid system that combines the collection of nadir & oblique images with Lidar in one sensor." fill={false}>
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-6 mb-4 text-center">
                        Products:
                        <Steps steps={[
                            {name: "", info: "Raw images as stereo couples"},
                            {name: "", info: "True-Orthomosaics"},
                            {name: "", info: "Oblique images from 4 directions."},
                            {name: "", info: "3D Lidar point clouds"},
                            {name: "", info: "3D Mesh (city)models"},
                            {name: "", info: "3D vector (city)models"},
                        ]} />
                    </div>
                    
                    {/* <div className="col-lg-6 mb-4">
                        <div className="view shadow-1-half rounded">
                            <img className="rounded img-fluid" src="https://mdbootstrap.com/img/Photos/Horizontal/Work/12-col/img%20(6).jpg" alt="Video title" />
                            <div className="mask flex-center rgba-black-light"></div>
                        </div>
                    </div> */}
                </div>
            </Section>
        </>
    )};
}

export default ServiceDetailProjects
