import React, { Component } from 'react'
import { ROUTES } from '../consts/index';
import HeaderCarousel from '../components/header-carousel';
import SolutionCard from '../components/solution-card';
// import TestimonialCarousel from '../components/testimonial-carousel';
import ClientCarousel from '../components/client-carousel';
import NewsPost from '../components/news-post';
import Section from '../components/sections/section';
import JobCards from '../components/job-cards';
import VideoSection from '../components/sections/video-section';
import ContactSection from '../components/sections/contact-section';

class Home extends Component {
    /* Add script to page */
    waitForElementToDisplay(selector, callback, checkFrequencyInMs, timeoutInMs) {
        let startTimeInMs = Date.now();
        (function loopSearch() {
            if (document.querySelector(selector) != null) {
                callback();
                return;
            }
            else {
                setTimeout(function () {
                    if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs)
                        return;
                    loopSearch();
                }, checkFrequencyInMs);
            }
        })();
    }

    componentDidMount() {
        this.waitForElementToDisplay('.customer-logos .slide', () => {
            const jsSlider = document.createElement("script");
            jsSlider.src = "/js/slider.js";
            jsSlider.async = true;
            document.body.appendChild(jsSlider);
        }, 200, 9000);

        // this.waitForElementToDisplay('.count-up', () => {
        //     const jsCounter = document.createElement("script");
        //     jsCounter.src = "/js/counter-animation.js";
        //     jsCounter.async = true;
        //     document.body.appendChild(jsCounter);
        // }, 200, 9000);

        const jsCarousel = document.createElement("script");
        jsCarousel.src = "/js/carousel.js";
        jsCarousel.async = true;
        document.body.appendChild(jsCarousel);
    }

    render() {
        return (
            <div data-spy="scroll" data-target="#navbarMenu" >
                <header>
                    <span id="home" className="scroll-fix-item"></span>
                    <HeaderCarousel />
                </header>

                <Section title="Services" fill={true} accentDark={true}
                    image={`${process.env.PUBLIC_URL}/img/sections/2planes_airport.jpg`}
                    overlayClass="rgba-black-light" blur={true}>
                    <span id="services" className="scroll-fix-item"></span>
                    <div className="row wow fadeIn my-auto justify-content-center">
                        <div className="col-12 col-sm-6 col-md-3 px-2 mb-4 mb-md-0">
                            <SolutionCard image="ortho_sm.jpg" title="Image content" text="Webviewer & Webservices" action={ROUTES.serviceDetailContent} />
                        </div>
                        <div className="col-12 col-sm-6 col-md-3 px-2 mb-4 mb-md-0">
                            <SolutionCard image="deep_learning_sm.jpg" title="Big data" text="Object inventory gathered by Machine Learning" action={ROUTES.serviceDetailDL} />
                        </div>
                        <div className="col-12 col-sm-6 col-md-3 px-2 mb-4 mb-md-0">
                            <SolutionCard image="point_cloud.jpg" title="Projects" text="Customized projects on demand using WM(T)S, 3D point clouds or 3D mesh" action={ROUTES.serviceDetailProjects} />
                        </div>
                        <div className="col-12 col-sm-6 col-md-3 px-2 mb-4 mb-md-0">
                            <SolutionCard image="verhardingskaart.jpg" title="Other products" text="GIS products on demand" action={ROUTES.serviceDetailOtherProducts} />
                        </div>
                    </div>
                </Section>

                {/* <Section title="Reviews" subtitle="">
            <span id="clients" className="scroll-fix-item"></span>
            <div className="text-center dark-grey-text">
                <TestimonialCarousel />
            </div>
        </Section>
        <hr className="my-2" />*/}

                <Section title="Some of our clients" subtitle="" accent={false}>
                    <span id="clients" className="scroll-fix-item"></span>
                    <div className="text-center dark-grey-text">
                        <ClientCarousel dataType="clients" />
                    </div>
                </Section>

                <Section title="Memberships" subtitle="" accent={true}>
                    <span id="memberships" className="scroll-fix-item"></span>
                    <div className="text-center dark-grey-text">
                        <ClientCarousel dataType="memberships" bgTransparent={true} />
                    </div>
                </Section>

                <Section title="News" fill={false}>
                    <span id="news" className="scroll-fix-item"></span>

                    <NewsPost title="Movie Meeting Day Digital Flanders 2023" image={''} video={'https://www.youtube.com/embed/cKROQiT156w?rel=0'}
                        excerpt={<>Movie Bruges 3DMesh.</>}
                        postDate="30/11/2023" link="https://youtu.be/cKROQiT156w"
                        flipContent={false} />

                    <hr className="my-5" />

                    <NewsPost title="Inventorization of asbestos using AI" image={`${process.env.PUBLIC_URL}/img/news/asbestos.png`}
                        excerpt={<>Plan3D inventories asbestos-containing roofs via AI on its high-resolution images of Belgium.</>}
                        postDate="16/11/2023" link={ROUTES.asbestosNews}
                        flipContent={true} link_new_tab={false} />

                    <hr className="my-5" />

                    <NewsPost title="Meeting Day Digital Flanders 2023" image={`${process.env.PUBLIC_URL}/img/news/Trefdag2023.jpg`}
                        excerpt={<>Meeting Day Digital Flanders 2023
                            'Connecting the dots', the central theme
                            The Trefdag Vlaanderen Digitaal, the community event for all those interested in digital transformation in Flanders, will take place on Thursday, October 26 in Ghent. The Flanders Digital Meeting Day inspires with innovation, motivates with fascinating examples and strengthens cooperation between governments and government & private. Discover the central theme and respond to the open call for speakers.</>}
                        postDate="16/10/2023" link="https://overheid.vlaanderen.be/trefdag-digitaal-vlaanderen"
                        flipContent={false} />

                    <hr className="my-5" />
                    
                    <NewsPost title="Municipalia 2023" image={`${process.env.PUBLIC_URL}/img/news/municipalia23.jpg`}
                        excerpt={<>A fair where agents and community representatives can contribute to the well-being of citizens 
                            by promoting their investments through the discovery of quality products and services.<br />
                            On 20 and 21 April 2023 at the WEX in Marche-en-Famenne.</>}
                        postDate="16/03/2023" link="https://www.municipalia.be/"
                        flipContent={true} />
                </Section>

                <Section title="About us" fill={false} fullWidth={true} accent={true}>
                    <span id="about" className="scroll-fix-item"></span>

                    <div className="row animated fadeIn my-auto justify-content-center">
                        <VideoSection contentText={<div>
                            In 1994, Vansteelandt was founded as a traditional topographic survey company by the two brothers Tom & Bert Vansteelandt. Through continuous innovation we evolved towards a trusted partner that searches for the best solutions for its clients.
                            At present, being member of the GEO Group (GEOxyz), Vansteelandt is focussing on its Plan3D products.
                            Plan3D is a collection of highly detailed full covering Aerial imagery (Nadir & Oblique) and Lidar data sets. A common level of detail is a GSD of 3-4 cm and 25-75 points/m². At present we operate with two planes of which one is equipped with a Leica Citymapper.
                            The Plan3D datasets can be used:
                            <ul>
                                <li>In a Web Viewer (license and API)</li>
                                <li>Ortho WM(T)S-service (license)</li>
                                <li>Project-base: we collect specific data according our client’s needs</li>
                                <li>GIS & CAD products</li>
                                <li>Big Data by Deep Learning</li>
                            </ul>
                        </div>}
                            frameTitle={'Plan3D Teaser'}
                            link={`https://www.youtube.com/embed/rhC8oWqtjBc?rel=0`}>
                        </VideoSection>
                    </div>
                </Section>

                <Section title="Jobs" fill={false} fullWidth={true} accent={false}>
                    <span id="jobs" className="scroll-fix-item"></span>
                    <JobCards />
                </Section>

                <ContactSection />
            </div>
        )
    };
}

export default Home
