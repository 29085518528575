import React from 'react';

const Counter = ({name, from, to, timeMs, unit, lineColor}) => {
    const unitEl = unit ? <p className="text-center">{unit}</p> : <></>;
    const line = lineColor ? <p className="m-2" style={{borderBottom: `2px solid ${lineColor}`}}></p> : <></>;

    return (
        <div className="col-12 col-sm-8 col-md-4 col-lg-3 mb-4 p-2">
            <div className="shadow-1 p-4 rounded bg-white">
                <p className="text-uppercase font-weight-normal mb-1 text-center">{name}</p>
                {line}
                <h4 className="fw-light text-right mb-0 count-up text-center" data-from={from} data-to={to} data-time={timeMs}>{to}</h4>
                {unitEl}
            </div>
        </div>
    )
}

export default Counter
