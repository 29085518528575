import React from 'react';
// import { ROUTES } from '../../consts/index';
// import { HashLink as Link } from 'react-router-hash-link';

const DeepLearningSection = () => {
    return (
        <section className="dark-grey-text section-fill">
            <div className="container pt-5 mb-5">
                <div className="row justify-content-center mb-2 mb-md-4">
                    <div className="col-xl-10 pl-3 d-flex align-items-center">
                        <div>
                            <h3 className="font-weight-bold mb-4">Big data</h3>
                            <p>Plan3D image content is a huge, full covering, database of very detailed nadir & oblique imagery combined with 3D point clouds. By Deep Learning techniques, we use this combination of unique information to extract recognisable objects. As such, by automated segmentation and detection, we are filling our Plan3D Big Data database. The database is structured by parcel, building, part of road and terrain. All items are GIS-objects with its geographical location and connected to the local common GIS-databases.</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-8 mb-4 mb-md-0">
                        <div className="view">
                            <img src={`${process.env.PUBLIC_URL}/img/service-details/big_data.jpg`} className="img-fluid" style={{minWidth: '100%'}} alt="Object segmentation" />
                        </div>
                    </div>
                </div>

                <div className="row justify-space-between align-items-center align-items-center mt-4">
                    <div className="col-md-8 mb-4 mb-md-0">
                        <div className="view">
                            <img src={`${process.env.PUBLIC_URL}/img/service-details/house.png`} className="img-fluid" alt="Building data" />
                        </div>
                    </div>
                    <div className="col-md-4 mb-4 mb-md-0">
                    <div className="view">
                            <img src={`${process.env.PUBLIC_URL}/img/service-details/house_info.png`} className="img-fluid" alt="Building data info" />
                        </div>
                    </div>
                </div>

                <div className="row justify-space-between align-items-center mt-4">
                    <div className="col-md-8 mb-4 mb-md-0">
                        <div className="view">
                            <img src={`${process.env.PUBLIC_URL}/img/service-details/facade.png`} className="img-fluid" alt="Facade data" />
                        </div>
                    </div>
                    <div className="col-md-4 mb-4 mb-md-0">
                        <div className="view">
                            <img src={`${process.env.PUBLIC_URL}/img/service-details/facade_info.png`} className="img-fluid" alt="Facade data info" />
                        </div>
                    </div>
                </div>

                <div className="row justify-space-between align-items-center mt-4">
                    <div className="col-md-8 mb-4 mb-md-0">
                        <div className="view">
                            <img src={`${process.env.PUBLIC_URL}/img/service-details/parcel.png`} className="img-fluid" alt="Parcel data" />
                        </div>
                    </div>
                    <div className="col-md-4 mb-4 mb-md-0">
                        <div className="view">
                            <img src={`${process.env.PUBLIC_URL}/img/service-details/parcel_info.png`} className="img-fluid" alt="Parcel data info" />
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center mt-4">
                    <div className="col-md-12 mb-4 mb-md-0">
                        <div className="view">
                            <img src={`${process.env.PUBLIC_URL}/img/service-details/house_oblique.png`} className="img-fluid" alt="Building oblique with data" />
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center p-4">
                    <div className="embed-responsive embed-responsive-16by9 shadow-1">
                        <iframe title={"Plan3D Big Data"} className="embed-responsive-item" src='https://www.youtube.com/embed/um4mwNnp6k8' allowFullScreen></iframe>
                    </div>
                </div>

            </div>
        </section>
    )
};

export default DeepLearningSection
