import React, {Component} from 'react'
import '../components/steps.css';

class Steps extends Component {
    render() { 
        let stepsList = [];
        for(let s = 0; s < this.props.steps.length; s++) {
            const step = this.props.steps[s];
            stepsList.push(
                <Step key={s} title={step.name} info={step.info} number={s+1} />
            );
        }
        return (
            <ol className="step pl-0">
                {stepsList}
            </ol>
        );
    };
}

const Step = ({ number, title, info }) => (
    <li className="step-element pb-0">
        <div className="step-number">
            <span className="number bg-info">{number}</span>
        </div>
        <div className="step-excerpt">
            { title ? <h6 className={`font-weight-bold dark-grey-text ${info ? "mb-3" : "mb-0"}`}>{title}</h6> : <></> }
            {info ? <p className={`${info && title ? "" : "mt-center mb-0"} text-muted`}>{info}</p> : <></>}
        </div>
    </li>
);

export default Steps;
