import React, {Component} from 'react'
import Section from '../components/sections/section';
import Steps from '../components/steps';

class AsbestosNews extends Component {
    render() { return (
        <>
            <Section title="Asbestos inventory" subtitle="Plan3D by Vansteelandt leads asbestos inventory for Interleuven." fill={false}>
                <div className="row d-flex justify-content-center wow fadeIn my-auto mb-0">
                </div>

                <div className='row justify-content-center mt-n-2 mb-2'>
                    <div className="col-md-5 col-sm-10 mb-4 mb-md-0">
                        <div className='card'>
                            <div className='card-body m-0 p-0'>
                                <div className="view">
                                    <img src={`${process.env.PUBLIC_URL}/img/asbestos-detail/dashboard-example.png`} className="img-fluid" alt="Suspected asbestos dashboard snapshot" />
                                    <p className='text-center text-note my-2'>Suspected asbestos dashboard snapshot</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row no-gutters align-items-center justify-content-center mb-2">
                    <div className="col-md-8 col-lg-7 order-md-2">
                        <p>
                            Plan3D by Vansteelandt has launched an innovative project commissioned by the Interleuven intermunicipal association to inventory potentially asbestos-containing roofs in the municipalities and cities within their area of operation.
                        </p>
                        <p>
                            Working closely with the Public Waste Agency of Flanders (OVAM) and Interleuven, Plan3D compiled a comprehensive catalog documenting all roof materials that may potentially contain asbestos.
                            To map potentially asbestos-containing roofs, Plan3D by Vansteelandt uses advanced Deep Learning and AI technology developed in-house. This technology is applied to high-quality images that the company acquires biannually throughout Flanders. These images offer unparalleled resolution and accuracy, enabling Plan3D to identify roofs containing asbestos with great precision.
                        </p>
                        <p>
                            A unique feature of this initiative is the continuous monitoring of the collected data. This monitoring ensures that the inventory is constantly updated to keep track of changes in roof status, such as remediation and new recordings. This allows the evolution of building asbestos roofs to be closely monitored.
                        </p>

                        <p>
                            The data collected will be invaluable in achieving the goals set by the Flemish Government to make all public buildings asbestos-safe by 2034 and all private buildings asbestos-safe by 2040. Asbestos-safe means that all asbestos applications in poor condition have been removed from buildings and homes. Thanks to the associated monitoring tool, concrete figures can be published for the relevant authorities.
                        </p>
                        <p>
                            In time, Plan3D by Vansteelandt has plans to roll out this data for all of Belgium and make it available to customers.
                        </p>
                    </div>
                </div>

                <div className="row justify-space-around mt-4">
                    <div className="col-md-6 mb-4 mb-md-0">
                        <div className='card'>
                            <div className='card-body m-0 p-0'>
                                <div className="view">
                                    <img src={`${process.env.PUBLIC_URL}/img/asbestos-detail/region.png`} className="img-fluid" alt="Suspected asbestos building area example" />
                                    <p className='text-center text-note my-2'>Suspected asbestos building area example</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mb-4 mb-md-0">
                        <div className='card'>
                            <div className='card-body m-0 p-0'>
                                <div className="view">
                                    <img src={`${process.env.PUBLIC_URL}/img/asbestos-detail/building.png`} className="img-fluid" alt="Suspected asbestos roof example" />
                                    <p className='text-center text-note my-2'>Suspected asbestos roof example</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Section>
        </>
    )};
}

export default AsbestosNews
