import React, {Component} from 'react'

class JobCard extends Component {
  constructor(props) {
    super(props);
    this.link = props.action;
    this.title = props.title;
    this.location = props.location;
  }

  clicked = () => {
    window.open(this.link, '_blank');
  }

  render() { return (
      <div className="card card--clickable shadow-1 h-100" onClick={this.clicked}>
        <div className="card-body center no-pointer-events">
            <h5 className="card-title">{this.title}</h5>
            <h6 className="card-subtitle mb-2 text-muted">{this.location}</h6>
            <p className="card-text"></p>
            <div className="text-center">
              <button className="btn btn-link p-2 m-0">Job description</button>
            </div>
        </div>
      </div>
  )};
}

export default JobCard
