// Path is used for links (href / button) and to is used in routing component
const ROUTES = {
  home: { page: "/", header: "/#home" },
  services: { path: "/#services", to: "#services" },
  clients: { path: "/#clients", to: "#clients" },
  partners: { path: "/#partners", to: "#partners" },
  news: { path: "/#news", to: "#news" },
  about: { path: "/#about", to: "#about" },
  jobs: { path: "/#jobs", to: "#jobs" },
  contact: { path: "/#contact", to: "#contact" },
  
  serviceDetail: { path: "/services/:type", to: "/services/" },
  serviceDetailDL: "/services/big-data",
  /* Big Data Forms */
  serviceDetailDLEnquetePrivate: "/services/big-data-form-private",
  serviceDetailDLEnquetePublic: "/services/big-data-form-public",
  serviceDetailProjects: "/services/projects",
  serviceDetailContent: "/services/content",
  serviceDetailOrder: "/services/order",
  serviceDetailOtherProducts: "/services/other-products",
  /* Inside 'serviceDetailContent' page */
  contentDetailWebviewer: "#webviewer",
  contentDetailWebservice: "#webservice",
  /* Inside 'serviceDetailOrder' page */
  serviceDetailOrderWhy: "#why",
  serviceDetailOrderWhat: "#what",
  serviceDetailOrderPrice: "#price",
  serviceDetailOrderOrder: "#order",
  /* News details */
  asbestosNews: "/news/asbestos",
  /* Client zone */
  clientZone: "/clientZone/",
  /* Redirects */
  asbestosDashboardAsbestos: "/interleuven/dashboard-asbestos/asbestos",
  asbestosDashboardArrondissement: "/interleuven/dashboard-asbestos/arrondissement",
  /* Legal */
  privacyPolicy: { path: "/legal/privacy#top", to: "/legal/privacy" },
  cookiePolicy: { path: "/legal/cookies#top", to: "/legal/cookies" },
  disclaimer: { path: "/legal/disclaimer#top", to: "/legal/disclaimer" },
};

const LAYOUT = [
  {path: ROUTES.services.path, name: "Services"},
  {path: ROUTES.clients.path, name: "Clients & Memberships"},
  {path: ROUTES.news.path, name: "News"},
  {path: ROUTES.about.path, name: "About us"},
  {path: ROUTES.jobs.path, name: "Jobs"},
  {path: ROUTES.contact.path, name: "Contact"},
];

export { ROUTES, LAYOUT };
